<template>
    <div class="search-wrapper" :class="{ 'is-droped': show}">
        <div class="search-btn">
            <a class="search-btn-inner" href="javascript:;" @click.stop="show=!show">
                <span>筛选</span>
                <i class="search-triangle" />
            </a>
        </div>
        <div class="search-drop">
            <dl class="search-drop-list">
                <dt class="search-drop-tit">系列</dt>
                <dd v-for="item in seriesList" :key="item.title" class="search-drop-item" :class="{ active: filterData.seriesid===item.seriesid }" @click.stop="handleClick('seriesid', item.seriesid)">{{item.title}}</dd>
                <dt class="search-drop-tit">性别</dt>
                <dd class="search-drop-item" v-for="item in sexList" :key="item.value" :class="{ active: filterData.sex===item.value }" @click.stop="handleClick('sex', item.value)">{{ item.name }}</dd>
            </dl>
            <div class="btn-wrapper">
                <a href="javascript:;" class="btn-primary btn-round" @click.prevent="handleConfirm">确定</a>
                <a href="javascript:;" class="btn-plain btn-round" @click.prevent="show=false">取消</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ListFilter',
    props: {
        seriesList: {
            type: Array,
            default: null
        },
        required: true
    },
    data() {
        return {
            sexList: [
                { name: '无', value: '1' },
                { name: '男', value: '2' },
                { name: '女', value: '3' }
            ],
            filterData: {
                seriesid: '',
                sex: 0
            },
            show: false
        }
    },
    methods: {
        handleClick(type, value) {
            if(this.filterData[type] !== value) {
                this.filterData[type] = value
            } else {
                this.filterData[type] = type === 'sex' ? '0' : ''
            }
        },
        handleConfirm() {
            this.$emit('handle-filter', this.filterData)
            this.show = false
        }
    },
    mounted() {
        const self = this
        this.$nextTick(_ => {
            document.querySelector('body').addEventListener('click', function() {
                console.log('body click')
                self.show = false
            })
        })
    }
}
</script>
<style lang="scss" scoped>
.search-wrapper {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 50%;
    width: 250px;
    height: 58px;
    font-size: 14px;
    margin-left: calc(600px - 250px);
    text-align: right;
    cursor: pointer;
}
.search-triangle {
    @include triangle('bottom', 5px, 8px, #999);
    @include inlineBlock();
    margin-left: 10px;
}
.search-btn {
    margin-right: -10px;
    position: relative;
    z-index: 9;
    @include inlineBlock();
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-right: 30px;
    background-color: $white;
}
.search-btn-inner {
    &:hover {
        color: $red;
        .search-triangle {
            @include triangle('bottom', 5px, 8px, $red);
        }
    }
}
.search-drop {
    position: absolute;
    z-index: 1;
    top: -234px;
    right: 0;
    width: 210px;
    text-align: left;
    transition: top .3s;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(204, 204, 204, 0.3);
}
.search-drop-list {
    padding: 17px 17px 10px;
    border-bottom: 1px solid $gray;
}
.search-drop-tit {
    margin-bottom: 10px;
}
.search-drop-item {
    @include inlineBlock();
    @include lineHeight(32px);
    width: 80px;
    margin-bottom: 10px;
    border-radius: 150px;
    text-align: center;
    background-color: $grayest;
    &:hover {
        color: $red;
    }
    &.active {
        color: $white;
        background-color: $red;
    }
    &:nth-child(3n) {
        margin-left: 10px;
    }
}
.btn-wrapper {
    padding: 17px;
}
.is-droped {
    .search-btn-inner {
        color: $red;
        .search-triangle {
            @include triangle('bottom', 5px, 8px, $red);
        }
    }
    .search-drop {
        top: 60px;
    }
}
</style>
