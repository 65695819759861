import { render, staticRenderFns } from "./pageAdvert.vue?vue&type=template&id=dd4da890&scoped=true&"
import script from "./pageAdvert.vue?vue&type=script&lang=js&"
export * from "./pageAdvert.vue?vue&type=script&lang=js&"
import style0 from "./pageAdvert.vue?vue&type=style&index=0&id=dd4da890&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd4da890",
  null
  
)

export default component.exports